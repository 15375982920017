$font-family-base: 'Inter';

$font-weight-regular: 500;
$font-weight-semibold: 600;
$h6-font-size: 1.125rem;

$navbar-brand-margin-end: 5rem;
$navbar-nav-link-padding-x: 1.68rem;
$nav-link-font-size: 1rem;
$nav-link-padding-x: 0;
$nav-link-font-weight: 600;

$input-border-width: 0.031rem;
$input-border-radius: 0.5rem;
$input-font-size: 0.875rem;
$input-padding-y: 0.5rem;
$form-check-input-border-radius: 0.125rem;
$form-select-border-radius: 0.5rem;

$btn-font-weight: 600;
$btn-border-radius: 0.375rem;
$btn-border-radius-sm: 0.375rem;
$btn-focus-box-shadow: none;

$placeholder-opacity-max: 1;

$card-border-radius: 0.75rem;
$dropdown-min-width: 13rem;
$modal-content-border-radius: 0.75rem;
$modal-inner-padding: 2.5rem;
$modal-header-padding: 1rem;
$progress-height: 0.5rem;
$form-select-color: #000;
//$tooltip-color: #344054;
//$tooltip-bg: #EAECF0;
//$tooltip-border-radius: 8px;
.small {
    font-size: 0.875rem !important;
}
