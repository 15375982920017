app-journalist-page, app-outlet-page {
  display: grid;
  grid-area: content;
  grid-template-rows: min-content min-content 1fr;
  grid-template-columns: auto;
  grid-template-areas: "header" "one" "two";
  grid-row-gap: 2px;
  position: relative;
  @include media-breakpoint-down(sm) {
    grid-template-areas: "header" "one" "two";
  }

  header {
    padding-bottom: 5px;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    grid-template-areas: "search";

    .search {
      grid-area: search;
    }

    .dashboard-buttons {
      grid-area: dashboard-buttons
    }

    @include media-breakpoint-down(sm) {
      grid-template-rows: auto auto 1fr;
      grid-template-columns: auto;
      grid-template-areas: "title" "search" "filters" "nav" "tags";
      nav, .search, .filters {
        margin-left: auto;
      }
      nav {
        .btn {
          display: inline-block;
        }
      }
    }
  }
}
