.dashboard-spinner {
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
}


.chart-wrapper {
    background: transparent;
    display: flex;
    align-items: stretch;
    justify-content: center;
    .chart-spinner {
        background-color: transparent;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
