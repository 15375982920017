#h-timeline-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
    position: relative;
    margin: 0 0 3rem;
    ul {
        list-style-type: none;
        text-align: center;
        width: 100%;
        margin: 0;
        padding: 0 2rem;
        li {
            display: inline-block;
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 1;
            span  {
                display: block;
                margin-bottom: .5rem;
                &:first-of-type {
                    color: $gray-400;
                    font-size: 1.5rem
                }
                &:nth-of-type(even) {
                    color: $gray-400;
                    font-size: 1.5rem;
                }
                &:last-of-type {
                    color: $gray-800;
                }
            }
            &:before {
                background: $gray-400;
                content: '';
                height: 3px;
                left: 0;
                right: 0;
                position: absolute;
                top: 51%;
                transform: translateY(-50%);
                z-index: -1;
            }
            &.completed {
                span {
                    color: $primary !important;
                }
                &:before {
                    background: $primary;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            li {
                margin-bottom: 1rem;
                text-align: left;
                width: 100%;
            }
        }
        @include media-breakpoint-up(lg) {
            li {
                width: 50%;
            }
        }
        @include media-breakpoint-up(xl) {
            li {
                width: 25%
            }
        }
    }
    .prev, .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .prev {left: 1rem;}
    .next {right: 1rem;}
}

#timeline {
	position: relative;
	padding: 2rem 0;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 18px;
		height: 100%;
		width: 4px;
		background: $gray-400;
	}
    @include media-breakpoint-up(lg) {
		&::before {
			left: 50%;
			margin-left: -2px;
		}
    }
}

.timeline-block {
	position: relative;
	margin: 2em 0;
	@include clearfix;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	@include media-breakpoint-up(lg) {
		margin: 4em 0;
		&:first-child {
            margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
        }
        &:nth-of-type(odd) {
            text-align: right;
        }
	}
}

.timeline-dot {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
    border-radius: 50%;
	@include media-breakpoint-up(lg) {
		width: 60px;
		height: 60px;
		left: 50%;
		margin-left: -30px;
	}
}

.timeline-block {
    &:nth-of-type(4n) {
        .timeline-dot {
            background: lighten($blue, 25%);
        }
    }
    &:nth-of-type(4n+1) {
        .timeline-dot {
            background: $green;
        }
    }
    &:nth-of-type(4n+2) {
        .timeline-dot {
            background: $blue;
        }
    }
    &:nth-of-type(4n+3) {
        .timeline-dot {
            background: $red;
        }
    }
}

.timeline-content {
    line-height: 1rem;
	position: relative;
	margin-left: 60px;
	background: white;
	border-radius: .25rem;
	padding: 1em;
	box-shadow: 0 0 3px $gray-600;
    @include clearfix;
    .card-edit {
        position: absolute;
        top: .5rem;
        right: .5rem;
    }
	&::before {
		content: '';
		position: absolute;
		top: 16px;
		right: 100%;
		height: 0;
		width: 0;
		border: 7px solid transparent;
        border-right: 7px solid $gray-600;
	}
	@include media-breakpoint-up(lg) {
		margin-left: 0;
		padding: 1.6em;
		width: 45%;
		&::before {
			top: 24px;
			left: 100%;
			border-color: transparent;
			border-left-color: $gray-600;
        }
        .timeline-block:nth-child(odd) & {
            .card-edit {
                position: absolute;
                top: .5rem;
                left: .5rem;
            }
        }
		.timeline-block:nth-child(even) & {
			float: right;
			&::before {
				top: 24px;
				left: auto;
				right: 100%;
				border-color: transparent;
				border-right-color: $gray-600;
			}
		}
	}
}

.note-card {
    width: 240px;
    min-height: 180px;
    position: relative;
    border-top-right-radius: 25px !important;

    &:after {
        content: '';
        width: 18px;
        height: 22px;
        background-image: url('../../../assets/css-images/card-folded-edge.png');
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
    }
}
