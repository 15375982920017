app-pitch {
    display: grid;
    grid-area: content;
    grid-template-rows:  min-content 1fr;
    grid-template-columns: auto;
    grid-template-areas: "header" "one";
    grid-row-gap: 2px;
    position: relative;
    @include media-breakpoint-down(sm) {
        grid-template-areas:
            "header"
            "one";
    }
    header {
        grid-template-rows: auto auto auto;
        grid-template-columns: auto 1fr auto;
        grid-template-areas:
            "title title search"
            "filters tags nav";
            .search {
                grid-area: search;
                margin-left: auto;
            }
            .filters {
                grid-area: filters;
                margin: .5rem inherit;
                display: flex;
            }
            nav {
                grid-area: nav;
                margin-left: auto;
                margin-top: .5rem;
            }
            .tags {
                grid-area: tags;
            }
            @include media-breakpoint-down(sm) {
                grid-template-rows:  auto auto 1fr;
                grid-template-columns: auto;
                grid-template-areas:
                    "title"
                    "search"
                    "filters"
                    "nav"
                    "tags";
                nav, .search, .filters {
                    margin-left: auto;
                }
                nav {
                    .btn {
                        display: inline-block;
                    }
                }
        }
    }
}
