app-nav-search-component {
    .bg-transparent-blue {
        --bs-bg-opacity: 0.1;
    }

    .form-control::placeholder {
        color: white;
        opacity: 0.5;
        font-weight: 400;
    }
}