.note-content {
  position: relative;

  > .btn-group {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}


.twitter {
  color: $twitter;
  background-color: $white-200;
}

.facebook {
  color: $facebook;
  background-color: $white-200;
}


.instagram {
  color: $instagram;
  background-color: $white-200;
}

.linkedin {
  color: $linkedin;
  background-color: $white-200;
}

.other {
  color: $other-link-color;
  background-color: $white-200;
}
