app-outlet-card {

    .media-list-contacts-card {
        min-height: 201px;
    }
    
    .thumbnail-image {
        width: 62px;
        height: 62px;
    }

    .social-media-icon {
        width: 32px;
        height: 32px;
        background-color: $white-200;

        &.linkedin {
            color: $linkedin;
        }

        &.twitter {
            color: $twitter;
        }
    }

    .phone-number-wrapper {
        width: 225px;
    }
}

