@mixin cardThemes {
    @each $name, $hex in $media-list-card-colors {
        &-#{"" + $name} {
            fa-icon {
                color: white;
            }
            fa-layers {
                color: white;
                .overlay {
                    color: $hex;
                }
            }
        }
    }
    &-default {
        fa-icon {
            color: $gray-400;
            &.primary-button {
                color: $primary;
            }
        }
        fa-layers {
            color: $primary;
            .overlay {
                color: white;
            }
        }
    }
}

app-contact-card-envelop {
    &.color-scheme {
        @include cardThemes();
    }
}

