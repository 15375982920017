@mixin tagThemes {
    @each $name, $hex in $media-list-card-colors {
        &-#{"" + $name} {
            .tag {
                background-color: white;
                color: $hex;
                .tag-title {
                    color: $hex;
                }
            }
        }
        &-default {
            .tag {
                background-color: $gray-200;
                color: $gray-700;
                .tag-title {
                    color: $gray-700;
                }
            }
        }
    }
}

app-contact-tags {
    .contact-tags-wrapper {
        position: relative;
    }

    .tag {
        background-color: $white-100;
        border-radius: 6px;
        line-height: 0;
        vertical-align: middle;
    }

    .tag-title {
        display: inline-block;
        line-height: 16px;
        text-align: left;

        &.can-delete {
            padding-right: 24px;
        }
    }
    
    &.color-scheme {
        @include tagThemes();
    }

}
