.tooltip {
    position: absolute;
}

.bs-tooltip-top .tooltip-arrow::before {
    top: auto;
    bottom: 0;
    left: 45%;
}

.tooltip.wide {
    .tooltip-inner {
        max-width: none;
    }
}
