.input-group-search {
    border-width: 0.5px;

    button {
        color: $gray-500;
        font-size: 12px
    }
}

.form-switch.form-switch-secondary .form-check-input:checked {
    background-color: $blue-300;
    border-color: $blue-300;
}

.form-control {
    transition: none;
    &:focus {
        box-shadow: none;
        transition: none;
    }
}
