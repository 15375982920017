@import '../_colors.scss';

@mixin dotThemes {
  @each $name, $hex in $media-list-card-colors {
    &-#{"" + $name} {
      background-color: $hex;
    }
  }
  &-default {
    background-color: white;
    border: 1px dotted $gray-600;
  }
}

html, body {
  height: 100%;
  min-height: 100vh;
}

body, .bg-body {
  background-color: $gray-100;
}

::selection {
  background: $primary;
}

.truncate {
  @media (max-width: 1500px) {
    display: inline-block;
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.bs-datepicker-head {
  background-color: $primary !important;
}

.hidden {
  display: none !important;
}

.dot {
  &.color-scheme {
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    @include dotThemes();
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.absolute-middle-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-top-right {
    transform: translate(0, -50%);
    top: 0;
    right: 0;
}

.cursor-pointer {
  cursor: pointer;
}

svg-icon {
  line-height: 0px;
}

::-webkit-resizer {
  background-color: transparent;
}

.highlighted-search {
    b {
        background: $primary-100;
        color: $primary;
        border-radius: 4px;
        padding: 2px;
    }

  .show-on-hover-container {
    &:hover {
      button {
        visibility: visible !important;
      }
    }
  }
}
