.dropdown-menu {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: visible;
    margin: 0;
    z-index: 1050;
    box-shadow: $box-shadow;
    typeahead-container.dropdown-menu {
        min-width: initial;
        width: 80%;
    }
    &.force-menu-right {
        right: -48px !important;
        left: auto !important;
    }
}

.dropdown-header {
    color: $primary
}

.dropdown-item {
    cursor: pointer;
    &:focus, &:hover, &:active {
        color: $white;
        background-color: $primary-200;
        outline: none;
    }
}

.btn-block.dropdown-toggle {
    &::after {
        display: none;
    }
}

.btn-group.show .dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
}

bs-dropdown-container {
    z-index: 9999 !important;
}

.dropdown-group {
    margin: 0;
    padding: 0;
    height: auto;
    max-height: 160px;
    overflow-y: auto;
}

.dropdown-menu-right {
    right: 0;
}

.dropdown-menu-link {
    .ngx-select__toggle {
        border: none;
        font-weight: 500;
        color: $secondary !important;
    }

    .ngx-select__toggle-buttons {
        margin-left: 8px;
    }

    .ngx-select__item_active {
        background-color: $primary !important;
    }
}


.ngx-select__item_active {
    background-color: $primary !important;
}

.ngx-select__toggle {
    padding-top: 7px;
    padding-bottom: 7px;
    color: $secondary !important;
    font-weight: 400;
}

.ngx-select__toggle-buttons .dropdown-toggle {
    &:before {
        font: var(--fa-font-regular);
        border: 0;
        content: "\f078";
    }
    &:after {
        display: none;
    }
}
