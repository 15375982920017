img {
    max-width: 100%;
}

.thumbnail {
    display: inline-block;
    position: relative;
    width: 100%;
    overflow: hidden;
    img {
        border-radius: 100%;
        object-fit: contain;
        height: 75px;
        width: 75px;
      }
  }

.initials-image {
    font-size: 16px;
    font-weight: normal;
    color: $gray-700;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $gray-700;
    height: 62px;
    width: 62px;
    line-height: 62px;
}

.new-initials-image {
    font-size: 1.5rem;
    font-weight: 500;
    color: $gray-700;
    text-align: center;
    height: 4rem;
    width: 4rem;
    line-height: 4rem;
    background-color: $sky-100;
}

.tag {
    padding: 4px 10px;
    background-color: $primary-100;
}

@media (max-width: 1600px) {
    .new-initials-image {
        font-size: 1.25rem;
        height: 3rem;
        width: 3rem;
        line-height: 3rem;
    }
}
