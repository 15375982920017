app-user-support-page {
  overflow: hidden;
  grid-area: content;
  @include media-breakpoint-down(sm) {
    grid-template-areas: "header" "one";
  }

  header {
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-template-areas:
            "title search"
            "filters nav";
    @include media-breakpoint-down(sm) {
      grid-template-rows:  auto auto 1fr;
      grid-template-columns: auto;
      grid-template-areas:
              "title"
              "search"
              "filters"
              "nav";
      nav, .search, .filters {
        margin-left: auto;
      }
      nav {
        .btn {
          display: inline-block;
        }
      }
    }
  }
}
