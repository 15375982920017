@mixin cardThemes {
    @each $name, $hex in $media-list-card-colors {
        &-#{"" + $name} {
            background-color: $hex;
            color: white;
            .lead {
                a,
                fa-icon {
                    color: white;
                }
            }
            .sub-title {
                color: white;
            }
            .info-buttons {
                fa-icon {
                    color: white;
                }
            }
            .circle-button {
                background: white;
                color: $hex;
            }
            .media-list-button {
                color: white;
                &:hover {
                    background-color: white;
                    color: $hex;
                    fa-icon {
                        color: $hex;
                    }
                }
                &:disabled {
                    color: rgba($white, 0.5);
                    background-color: transparent !important;
                }
            }
            fa-icon {
                color: white;
            }
            fa-layers {
                color: white;
                .overlay {
                    color: $hex;
                }
            }
            input[type='checkbox'],
            .checkbox input[type='checkbox'],
            .checkbox-inline input[type='checkbox'] {
                &:checked:after {
                    background-color: white;
                    border-color: white;
                }
                &:checked:before {
                    border-color: $hex;
                }
                &:after {
                    border-color: white;
                }
            }
            .initials-image {
                color: white;
                border-color: white;
            }
        }
    }
    &-default {
        color: $gray-600;
        fa-icon {
            color: $gray-400;
            &.primary-button {
                color: $primary;
            }
        }
        fa-layers {
            color: $primary;
            .overlay {
                color: white;
            }
        }
        input[type='checkbox'],
        .checkbox input[type='checkbox'],
        .checkbox-inline input[type='checkbox'] {
            &:checked:after {
                background-color: $primary;
                border-color: $primary;
            }
            &:checked:before {
                border-color: white;
            }
            &:after {
                border-color: $gray-500;
            }
        }
        .text-secondary {
            color: $gray-300 !important;
        }
        .lead {
            a,
            fa-icon {
                color: $gray-800;
            }
        }
        .sub-title {
            color: $gray-800;
        }
        .info-buttons {
            fa-icon {
                color: $gray-400;
            }
        }
        .primary-button {
            color: $primary;
        }
        .circle-button {
            background: $gray-200;
            color: $gray-700;
        }
        .media-list-button {
            color: $gray-400;
            &:hover {
                background-color: $gray-400;
                color: white;
                fa-icon {
                    color: white;
                }
            }
            &:disabled {
                color: rgba($gray-400, 0.5);
                background-color: transparent !important;
            }
        }
        .initials-image {
            color: $gray-700;
            border-color: $gray-700;
        }
    }
}

app-journalist-card {
    .thumbnail-image {
        width: 62px;
        height: 62px;
    }

    .contact-thumbnail {
        width: 62px;
        height: 62px;
        overflow: hidden;

        > .social-icons {
            display: none;
        }

        &:hover > .social-icons {
            display: flex;
        }
    }

    .journalist-email b {
        font-weight: 400;
    }

    .pitched-link {
        color: $blue-600;
    }

    .pitched-propel-icon {
        width: 16px;
    }

    .sub-title {
        display: block;
        max-width: 300px;
    }

    .media-list-contacts-card {
        min-height: 260px;
    }

    .pills-more {
        background-color: $gray-100;
        border-radius: 6px;
        vertical-align: middle;
    }

    .journalist-card-dropdown {
        width: 237px;
    }

}
