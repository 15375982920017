.highcharts-exporting-group {
    display: none;
}

highcharts-chart:hover .highcharts-exporting-group {
    display: block;
}

.badge-warning {
    color: #854D0E;
    font-weight: 500;
    background-color: #FFEEC1;
}

.badge-success {
    color: #29B291;
    font-weight: 500;
    background-color: #E5F9F4;
}

.badge-info {
    color: #FFFFFF;
    font-weight: 500;
    background-color: #6C58BD;
}

.badge-light {
    color: #344054;
    font-weight: 500;
    background-color: #FFFFFF;
    border: 0.5px solid  #D0D5DD;
}

.outer-circle {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.circle {
    display: inline-block;
    background-color: $gray-600;
    cursor: pointer;
}

.circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 40px;
    width: 40px;
    font-size: 1rem;
}

.row-border {
    border: 0.5px solid #D0D5DD;
}

.text-primary-600 {
    color: $primary-600;
}

.text-gray-900 {
    color: $gray-900;
}

