@mixin cardThemes {
    @each $name, $hex in $media-list-card-colors {
        &-#{"" + $name} {
            background-color: $hex;

            .pill-title,
            .dropdown-menu .text-foreground-200{
                color: $gray-900 !important;
            }
        }
    }
    &-default {
        color: $gray-600;
        fa-icon {
            color: $gray-400;
            &.primary-button {
                color: $primary;
            }
        }
        fa-layers {
            color: $primary;
            .overlay {
                color: white;
            }
        }
        input[type='checkbox'],
        .checkbox input[type='checkbox'],
        .checkbox-inline input[type='checkbox'] {
            &:checked:after {
                background-color: $primary;
                border-color: $primary;
            }
            &:checked:before {
                border-color: white;
            }
            &:after {
                border-color: $gray-500;
            }
        }
        .text-secondary {
            color: $gray-300 !important;
        }
        .lead {
            a,
            fa-icon {
                color: $gray-800;
            }
        }
        .sub-title {
            color: $gray-800;
        }
        .info-buttons {
            fa-icon {
                color: $gray-400;
            }
        }
        .primary-button {
            color: $primary;
        }
        .circle-button {
            background: $gray-200;
            color: $gray-700;
        }
        .media-list-button {
            color: $gray-400;
            &:hover {
                background-color: $gray-400;
                color: white;
                fa-icon {
                    color: white;
                }
            }
            &:disabled {
                color: rgba($gray-400, 0.5);
                background-color: transparent !important;
            }
        }
        .initials-image {
            color: $gray-700;
            border-color: $gray-700;
        }
    }
}
