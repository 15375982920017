*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-thumb {
  background: rgba(121, 81, 214, .5);
  border-radius: 4px;
  opacity: 0.6;
}
