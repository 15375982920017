.circle-progress {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: auto;
    .nav-tabs {
        border-bottom: none;
        margin: 0 15% 3rem;
        padding: 0;
        justify-content: space-between;
        position: relative;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        &:after {
            background-color: $gray-300;
            content: "";
            height: 3px;
            position: absolute;
            width: 95%;
            top: 30%;
            transform: translateY(-50%);
            z-index: 0;
        }
        .nav-item {
            display: flex;
            justify-content: center;
            z-index: 1;
            .nav-link {
                align-items: center;
                border: none;
                color: $gray-600;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding: 0;
                span:nth-of-type(2) {
                    height: 3rem; width: 3rem;
                    line-height: 2.75rem;
                    margin: 0; padding: 0;
                    margin-bottom: .75rem;
                    background-color: white;
                    border: 3px solid $gray-300;
                    border-radius: 100%;
                    display: block;
                    font-size: 1.25rem;
                    font-weight: 500;
                    text-align: center;
                }
                &.active {
                    background-color: transparent;
                    color: $primary;
                    span:nth-of-type(2) {
                        border-color: $primary;
                        color: $primary;
                    }
                }
            }
        }
    }
    .tab-content {
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .tab-pane.active {
            flex: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
