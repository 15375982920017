app-notifications-page {
  display: grid;
  grid-area: content;
  grid-template-rows: min-content 1fr;
  grid-template-columns: auto;
  grid-template-areas: "header" "one";
  grid-row-gap: 2px;
  position: relative;
  @include media-breakpoint-down(sm) {
    grid-template-areas: "header" "one";
  }

  header {
    display: flex;
    justify-content: flex-start;
  }
}
