app-broadcast-alert-form {
  .form-check {
    display: flex;
    height: 30px;
    align-items: center;
  }

  .form-group {
    margin-bottom: 2rem;
  }

  app-broadcast-alert-form-doc {
    background-color: $gray-100;
  }
}
