app-dashboards {
  .nav-link {
    color: #607c88;
      font-weight: 500 !important;

    fa-icon {
      color: $secondary;
    }

    &.active {
      fa-icon {
        color: white;
      }

      color: white;
    }
  }
}
