@import 'contact-card.themes';


app-contact-card {
    .color-scheme {
      @include cardThemes;
    }

    app-contacts-bulk-actions .show-buttons {
      flex-direction: column;

      .media-list-button {
        border: none;
        background-color: transparent;
      }
    }

    .dropdown-menu {
      min-width: auto;
    }

    .thumbnail-image {
      width: 62px;
      height: 62px;
    }

    .pitched-propel-icon {
      width: 16px;
    }

    .app-contact-engagement {
      margin-left: -16px;
    }

    .initials {
      width: 24px;
      height: 24px;
      background-color: $teal-100;
      font-size: 12px;
      color: $green-900;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      line-height: 23px;
    }

    .pitched-logo {
      width: 16px;
    }
}
   