#leftPart, #rightPart {
    height: 100vh;
}

#leftPart {
    background-color: $blue;
    background-image: url(/assets/css-images/loginscreen.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: -2px;
}

#rightPart {
    background-color: $white;
    .button-wrapper {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
