.navigation-item {
    .nav-link {
        position: relative;
        padding: 10px 13px;
        font-weight: 500;
        color: $nav-link-color;
        background: none !important;
        border: 0;

        &.active {
            font-weight: 700;
            color: $gray-900;
        }

        &.active::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $gray-900;
            border-radius: 20px 20px 0px 0px;
        }

        &:hover {
            font-weight: 700;
            color: $gray-900;
        }

    }

    &.secondary .nav-link {
        &.active::after {
            background-color: $orange;
        }
    }
}

