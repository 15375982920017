.ant-dropdown-menu-scroll-item {
    max-height: 200px;
    overflow-y: scroll;
    padding: 0 0 0 0;
}

.ant-dropdown-trigger {
    box-shadow: none;
    display: inline;
    vertical-align: bottom;
    &:hover {
        &:focus {
            color: white;
        }
    }
}

.ant-dropdown-menu,
.ant-dropdown-menu-root,
.ant-dropdown-menu-light,
.ant-dropdown-menu-vertical {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 200px;
    overflow: auto;
    margin: 0;
    z-index: 1050;
    .ant-dropdown-menu-submenu-title, .ant-dropdown-menu-item  {
        font-size: .85rem;
        color: $secondary;
        cursor: pointer;
        &:focus, &:hover, &:active {
            color: $black;
            background-color: $gray-200;
            outline: none;
        }
        &.ant-dropdown-menu-input-item {
            &.focus, &:hover, &:active {
                background-color: white;
            }
        }
    }
    .ant-divider {
        margin: 5px 0;
    }
    .ant-dropdown-inner-menu {
        height: 200px;
    }
}
