app-users-upload {
    display: grid;
    grid-area: content;
    grid-template-rows:  min-content 1fr;
    grid-template-columns: auto;
    grid-template-areas: "header" "one";
    grid-row-gap: 2px;
    position: relative;
    @include media-breakpoint-down(sm) {
        grid-template-areas:
            "header"
            "one";
            height: 100%;
    }
    header {
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
        grid-template-areas:
            "title search"
            "filters nav";
            .search {
                grid-area: search;
                margin-left: auto;
            }
            .filters {
                grid-area: filters;
                margin: .5rem inherit;
                display: flex;
            }
            nav {
                grid-area: nav;
                margin-left: auto;
                margin-top: .5rem;
            }
            @include media-breakpoint-down(sm) {
                grid-template-rows:  auto auto 1fr;
                grid-template-columns: auto;
                grid-template-areas:
                    "title"
                    "search"
                    "filters"
                    "nav";
                nav, .search, .filters {
                    margin-left: auto;
                }
                nav {
                    .btn {
                        display: inline-block;
                    }
                }
        }
    }
}
