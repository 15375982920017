$primary-100: #f2f4f7;
$primary-200: #9170DD;
$primary-300: #7951D6;
$green: #32D583;
$green-50: #FCFDFF;
$green-100: #EEFFFB;
$green-600: #2EC6A1;
$green-900: #002D2D;
$teal-50: #F0FAFD;
$teal-100:  #91E0D6;
$white-100: #F9FAFB;
$white-200: #F8FAFC;
$white-300: #F2F4F7;

$gray-50: #EAECF0;
$gray-100: #F8F8FA;
$gray-200: #D0D5DD;
$gray-400: #98A2B3;
$gray-500: #8C9DB9;
$gray-600: #8C9DB9;
$gray-900: #344054;
$orange: #FF7B24;
$orange-200: #FB9D5F;
$blue-100: #E7F1FF;
$blue-300: #67C6DF;
$blue-400: #00A4FF;
$blue-600: #58A9BF;
$blue-700: #0074B5;
$blue-800: #387DE1;
$blue-900: #2563EB;
$yellow-100: #FFFAEB;
$yellow-500: #E8B632;
$purple-100: #EEF2FF;
$purple-600: #4F46E5;
$pink-100: #FDF4FF;
$pink-600: #F857A6;
$red-100: #FEE4E2;
$red-600: #D92D20;
$danger: $red-600;
$primary: #6745B6;
$secondary: #667085;
$tertiary: $gray-400;

$cyan: #76E2FF;

//$grays: () !default;
//$grays: map-merge((
//    "100": $gray-100,
//    "200": $gray-200,
//    "300": $gray-300,
//    "400": $gray-400,
//    "500": $gray-500,
//    "600": $gray-600,
//    "700": $gray-700,
//    "800": $gray-800,
//    "900": $gray-900
//), $grays);

$badge-color-100: #FFC4B0;
$badge-color-200: #FDC9D0;
$badge-color-300: #FFC96B;
$badge-color-400: #91E0D6;
$badge-color-500: #92E6B5;
$badge-color-600: #FFC96B;

$text-white: $white-100;
$border-color: $gray-200;
$blue:    #2a96b0 !default;
$indigo:  #004862 !default;
$purple:  #a068e5 !default;
$pink:    #e83e8c !default;
$red:     #f62d51 !default;
$orange:  #fd7e14 !default;
$orange2:  #F9AA27 !default;
$yellow:  #ffc107 !default;
$green:   #0e9e58 !default;
$teal:    #54e2b2 !default;
$cyan:    #17a2b8 !default;
$light-blue: #D8FAFF !default;

$twitter: #1DA1F2;
$facebook: #4267B2;
$youtube: #FF0000;
$pinterest: #E60023;
$reddit: #FF4500;
$instagram: #E56053;
$linkedin: #0077B5;
$google-plus: #EA4335;
$other-link-color: $primary-200;

$navbar-light-color: rgba(255, 255, 255, 0.7);
$dropdown-link-active-color: white;
$navbar-light-active-color: $white-100;
$navbar-light-hover-color: $white-100;
$nav-tabs-border-color: $border-color;
$nav-link-color: $gray-400;
$link-color: $primary-300;
$input-border-color: $gray-200;
$input-placeholder-color: $gray-400;
$form-check-input-border: 1px solid $input-border-color;
$form-check-input-checked-bg-color: $primary-300;
$form-check-input-checked-border-color: $primary-300;
$progress-bg: $purple-100;
$table-striped-bg: $white-100;

$success: #2EC6A1;
$info: #6366F1;
$warning: #FFC837;
$danger: #D92D20;
$font-size-base: 1rem;


$media-list-card-colors: (
    "red": #ff4d4e,
    "red-dark": #a8071a,
    "yellow": #fdc223,
    "green": #73d13d,
    "green-dark": #237804,
    "blue": #40a9ff,
    "blue-dark": #0050b3,
);

$custom-colors: (
  "primary-100": $primary-100,
  "light-100": $white-100,
  "light-200": $green-50,
  "foreground": $gray-900,
  "foreground-200": $secondary,
  "info-100": $gray-200,
);

@mixin backgroundColors {
    @each $name, $hex in $media-list-card-colors {
        &-#{unquote($name)} {
            background-color:  lighten($hex, 50%);
        }
    }
}

$nav-bg: #475467;
$primary-400: #F2EEFB;
$primary-500: #DFD5F5;
$primary-600: #6C58BD;
$primary-700: #5E48B7;
$primary-800: #5742A8;

$text-primary: $gray-900;
$text-tertiary: $tertiary;
$text-link: #00A3FF;
$text-link-hover: #0074B5;
$border-primary: $gray-200;

$text-light: #F9FAFB;
$btn-secondary: #5FABBE;
$btn-secondary-hover: #4E9DB1;
$btn-secondary-focused: #418393;


$purple-brand: #7A48EA;
$red-orange: #F04438;
$gray-95: #FEF2F2;
$sky-100: #E0F2FE;