.profile-tabs {
    position: relative;
    .timeline {
        position: absolute;
        top: 0;
    }
    .nav.nav-tabs {
        border: none;
        margin-left: 5.5rem;
        .nav-link {
            border: none;
            &.active {
                border-bottom: 3px solid $primary;
            }
        }
    }
}

.profile-group {
    .card {
        border-radius: 0;
        border-left: none;
        border-right: none;
        .card-body {
            position: relative;
            border-right: 1px dotted $gray-300;
        }
        &:last-of-type {
            .card-body {
                border-right: none;
            }
        }

    }
    &:first-of-type {
        .card {
            border-bottom: none;
        }
    }
}

.cell-overflow {
    .datatable-body-cell-label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
    }
}
