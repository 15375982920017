@mixin rowThemes {
    @each $name, $hex in $media-list-card-colors {
        &-#{"" + $name} {
            background-color: $hex !important;
            color: white;
            .text-foreground {
                color: white !important;
            }
            a {
                color: white;
            }
            fa-icon {
                color: white;
            }
            fa-layers {
                color: white;
                .overlay {
                    color: $hex;
                }

            }
            .media-list-button {
                color: white;
                &:hover {
                    background-color: white;
                    fa-icon {
                        color: $hex;
                    }
                }
            }
            .circle-button {
                background: white;
                color: $hex;
            }
            input[type='checkbox'],
            .checkbox input[type='checkbox'],
            .checkbox-inline input[type='checkbox'] {
                &:checked:after {
                    background-color: white;
                    border-color: white;
                }
                &:checked:before {
                    border-color: $hex;
                }
                &:after {
                    border-color: white;
                }
            }
        }
    }
    &-default {
        background-color: inherit;
        color: $gray-600;
        a {
            color: $primary;
        }
        fa-icon {
            color: $gray-400;
            &.primary-button {
                color: $primary;
            }
        }
        fa-layers {
            color: $primary;
            .overlay {
                color: white;
            }
        }
        .media-list-button {
            color: $gray-400;
            &:hover {
                background-color: $gray-400;
                color: white;
                fa-icon {
                    color: white;
                }
            }
        }
        .circle-button {
            background: $gray-200;
            color: $gray-700;
        }
        input[type='checkbox'],
        .checkbox input[type='checkbox'],
        .checkbox-inline input[type='checkbox'] {
            &:checked:after {
                background-color: $primary;
                border-color: $primary;
            }
            &:checked:before {
                border-color: white;
            }
            &:after {
                border-color: $gray-500;
            }
        }
    }
}


