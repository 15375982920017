$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

$utilities-colors: map-merge(
  $theme-colors-rgb,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body":  to-rgb($body-color)
  )
);

$utilities-bg: map-merge(
  $utilities-colors,
  (
    "black": to-rgb($black),
    "white": to-rgb($white),
    "body": to-rgb($body-bg),
  )
);

$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg");
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");

