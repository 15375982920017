@import 'contact-row.themes';
@import '../../../mixins/flex-table';

$checkboxColumn: 4%;
$iconColumn: 5%;
$statsColumn: 13%;
$userColumn: 9%;

.contact-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    width: 100%;
    margin:0;
    border: 1px solid rgba(0,0,0,.125);
    &:nth-child(odd) {
        background: white !important;

    }

    &.selected {
        background: $primary !important;
    }
    @include flexTable(11);
    &.color-scheme {
        @include rowThemes();
    }
    a, button {
        cursor: pointer;
        vertical-align: middle;
    }
    button.no-action {
        cursor: default;
    }
    .flex-table-column {
        width: calc((100% - ($iconColumn * 4) - $statsColumn - $checkboxColumn) / 3) !important;

    }
    .iconColumn {
        width: $iconColumn !important;
        position: relative;
    }
    .statsColumn {
        width: $statsColumn !important;
    }
    .userColumn {
        width: $userColumn !important;
    }
    .actionColumn {
        width: ($iconColumn * 3 ) !important;
        padding-left: .5rem;
        padding-right: .5rem;
    }
    .checkboxColumn {
        width: $checkboxColumn !important;
        padding-left: .5rem;
        padding-right: .5rem;

    }
    .email-button {
        font-size: 1rem;
        padding: 0;
    }
    .media-list-button {
        height: 3rem;
        width: 3rem;
    }
    fa-layers {
        display: block;
    }
    input[type='checkbox']:after, .checkbox input[type='checkbox']:after, .checkbox-inline input[type='checkbox']:after {
        width: 16px;
        height: 16px;
    }
    input[type='checkbox']:checked:before, .checkbox input[type='checkbox']:checked:before, .checkbox-inline input[type='checkbox']:checked:before {
        width: 5px;
        height: 10px;
    }
    .initials {
        background-color: $teal-100;
        color: $green-900;
        border-radius: 50%;
        text-align: center;
    }
}

.contact-row .notes-tip {
    hr {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
        border-color: white !important;
    }

    .content {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
