@import 'scss/colors';
@import 'scss/mixins/borders';

.plg-content-bg {
    background: linear-gradient(221.42deg, #8E68E7 6.17%, #5F3EAC 48.91%, #7951D6 98.88%);
}

.plg-thumbnail-image {
    width: 62px;
    height: 62px;
    border-radius: 10px;
}

.plg-thumbnail-image-icon {
    font-size: 16px;
    font-weight: normal;
    color: $gray-700;
    text-align: center;
    border: 1px solid $gray-700;
    height: 62px;
    width: 62px;
    line-height: 62px;
    border-radius: 10px;
}

.plg-blue-bg {
    background: #76E2FF !important;
}


.link-img-h {
    height: 60%;
    width: fit-content;
}

.bg-purple-brand {
    background-color: $purple-brand !important;
}

.spinner-container {
    position: relative;
    width: 100%;
    max-height: 100%; /* Ensure it doesn't overflow the parent element */

    height: auto;
    padding-top: 0; /* Remove padding-top */
    aspect-ratio: 1; /* Maintain square aspect ratio */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 48px;
    background-color: #683AD0;

    .spinner {
        position: absolute;
        width: 80%;
        height: 80%;
        left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10%;

        .spinner-outer, .spinner-inner {
            position: absolute;
            border: 16px solid #683AD0;
            border-top: 16px solid #9D72FF;
            border-radius: 50%;
            animation: spin 2s linear infinite;
        }

        .spinner-outer {
            width: 100%;
            height: 100%;
        }

        .spinner-inner {
            width: 85%;
            height: 85%;
            animation-duration: 3s;
        }
    }


    .spinner-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}


.br-25 {
    @include borderRadiusPx(25);
}

.form-floating {
    border-radius: 10px;

    input {
        border: 0;
        border-radius: 10px;
    }
}

.bg-topics {
    background: #9566FF !important;
}

.propeller-left {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    left: -55%;
}

.propeller-right {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    right: -50%;
}

.z-index-100 {
    z-index: 100;
}
