@import "scss/colors";

@mixin buttonThemes {
  @each $name, $hex in $media-list-card-colors {
    color: $primary;
    &-#{$name} {
      .media-list-button {
        color: white;

        &:hover {
          background-color: $white;
          color: $hex;
        }

        &:disabled {
          color: rgba(white, 0.5);
          background-color: transparent !important;
        }
      }
    }
    &-default {
      .media-list-button {
        color: $gray-400;

        &:hover {
          background-color: $gray-400;
          color: white;
        }

        &:disabled {
          color: rgba($gray-400, 0.5);
          background-color: transparent !important;
        }
      }
    }
  }
}

@mixin colorsBackground {
    @each $name, $hex in $media-list-card-colors {
        &-#{"" + $name} {
            background-color:  $hex;
        }
    }
}

app-contacts-bulk-actions {
  a, button {
    cursor: pointer;
  }

  .show-buttons {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &.color-scheme {
    @include buttonThemes();
  }

  &.small {
    .media-list-button {
      font-size: 20px;
      height: 36px;
      width: 36px;
    }
  }
}

.color-dropdown-menu {
  max-width: 300px;

  .color-dropdown-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .color-scheme {
      height: 48px;
      width: 48px;
      border: none;
      border-radius: 100%;
      margin: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.color-scheme-default {
        border: 1px dotted $gray-600;
      }

      &:active {
        outline: none;
      }

      @include colorsBackground;
    }
  }
}

app-contacts-bulk-actions {
  .ant-dropdown-trigger {
    display: inline-flex;
  }

  .note-text-wrapper {
    margin-right: 0;

    .note-text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .notes-dropdown {
    max-width: 400px;

    .dropdown-group {
      overflow-x: hidden;

      li {
        font-size: 13px;
        padding: 5px;
        &:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
