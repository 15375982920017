@import '../colors';

@mixin flexTable($columnCount) {
    .flex-table-container {
        display: block;
        position: relative;
        height: 100%;
        overflow: hidden;
        border: 1px solid $border-color;
        border-radius: 8px;

        .flex-table-head {
            .flex-table-row {
                background-color: $white-100;
                border-top: none;
                overflow-y: scroll;
            }
        }
    }

    .flex-table-row {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0;
        width: 100%;
        border-top: 1px solid $border-color;

        &:nth-of-type(even) {
            background-color: $white-100;
        }

        &:last-child {
            border-bottom: 1px solid $border-color;
        }

        &:first-child {
            border-top: none;
        }
    }
    .flex-table-column {
        font-size: .875rem;
        font-weight: 500;
        padding: 0px 16px;
        height: 54px;
        border-right: 1px solid $border-color;
        overflow: hidden;
        display: flex;
        align-items: center;

        &:last-child {
            border-right: none;
        }

        &.no-wrap {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        width: 100% / $columnCount;
    }
    .flex-table-column-span {
        font-size: .95rem;
        padding: .5rem;
        &.no-wrap {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        width: 100%;
    }
    .flex-table-body {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        .flex-table-column {
            color: #495057;
        }
    }
}

$breakpoint-xl: 1920px;
$breakpoint-lg: 1745px;
$breakpoint-md: 1536px;
$breakpoint-sm: 1280px;
$breakpoint-xs: 300px;

@mixin flexTableBreakpoints($columnWidth) {
    @media (min-width: $breakpoint-xs) { width: ($columnWidth * .3) !important; }
    @media (min-width: $breakpoint-sm) { width: ($columnWidth * .5) !important; }
    @media (min-width: $breakpoint-md) { width: ($columnWidth * .7) !important; }
    @media (min-width: $breakpoint-lg) { width: ($columnWidth * .9) !important; }
    @media (min-width: $breakpoint-xl) { width: $columnWidth !important; }
}
