@import '../../../scss/colors';

app-contact-page {
    .nav.nav-tabs {
        background: $white;
        padding: 16px 16px 0 16px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .card.top-radius-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .carousel-control-next,
    .carousel-control-prev {
        right: 0;
        top: -41px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: white;
        border: 1px solid $border-color;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        content: '';
        position: relative;
        display: inline-block;
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: $secondary;
        height: 9px;
        width: 9px;
        left: -2px;
        transform: rotate(45deg);
    }

    .carousel-control-prev {
        left: auto;
        right: 45px;
    }

    .carousel-control-prev-icon {
        transform: rotate(-135deg);
        left: 2px;
    }

    app-articles {
        height: 245px;

        .container-fluid {
            padding-left: 0;
            padding-right: 8px;
        }
    }

    .twitter-feed-column {
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

    }

    .circle-btn-sm {
        width: 27px;
        height: 27px;
    }

    app-contacts, #noResults, #loading {
        grid-area: two;
        position: relative;
    }

    .contact-thumbnail {
        width: 60px;
        height: 60px;
        img {

        }
    }
}
