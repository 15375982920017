app-messaging {
  div {
    // update variables
    // color: $component-active-color;
    // background-color:  $component-active-bg;
    font-weight: 800;
  
    button {
      // color: $component-active-color;
      font-weight: 800;
      text-decoration: underline;
      padding-left: 0;
    }
  }
}
