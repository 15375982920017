.ngx-table-wrapper {
  height: 222px;
  border-bottom: 1px solid $border-color;
  border-radius: 8px;
  overflow: hidden;

  &.no-scroll {
    height: auto;

    .ngx-datatable.bootstrap .datatable-body .datatable-row-wrapper:last-child .datatable-body-row {
      border-bottom: none;
    }

    .datatable-body-row .datatable-body-cell:last-child {
      border-right: 1px solid $border-color;
    }
  }
}

.ngx-datatable.bootstrap {
  font-size: 0.875rem;
  .datatable-header {
    border-top-left-radius: 8px;
    border-top: 0.5px solid $border-color;
    border-bottom: 0.5px solid $border-color;
    border-top-right-radius: 8px;
    background-color: $white-100;
  }
  
  .datatable-header .datatable-header-cell {
    border-left: 0.5px solid $border-color;
    border-bottom: 0;
    padding: 0.5rem;
  }

  .datatable-header-cell {
    font-weight: 600;
  }

  .datatable-body {
    border-bottom: 0.5px solid $border-color;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .datatable-body-row.datatable-row-even {
      background-color: white;
    }

    .datatable-body-row.datatable-row-odd {
      background-color: $white-100;
    }

    .datatable-body-row .datatable-body-cell {
      border-left: 0.5px solid $border-color;
    }

    .datatable-body-row.datatable-row-odd.active,
    .datatable-body-row.datatable-row-even.active {
      background-color: $primary-200;
    }

    .datatable-row-wrapper:last-child .datatable-body-row {
      border-bottom: 0.5px solid $border-color;
    }

    .datatable-row-wrapper:first-child .datatable-body-row {
      border-top: none;
    }

    .datatable-body-cell-label {
      font-weight: 500;
    }
  }

  .table-row-check {
    border-radius: 2px;
    margin-top: 4px;
    margin-left: 7px !important;
    font-size: 16px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    border-radius: 8px;
    border: 1px solid $border-color;
    pointer-events: none;
  }
}

.table-striped {
  tr, td {
    border-bottom: none;
  }
}
