@import 'ngx-bootstrap/datepicker/bs-datepicker.scss';
@import '@siemens/ngx-datatable/index.scss';
@import '@siemens/ngx-datatable/themes/bootstrap.scss';

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.bubble.css";
@import "quill/dist/quill.snow.css";
//@import "quill-mention/dist/quill.mention.min.css";

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import './_fonts.scss';
@import './_colors.scss';
@import './_bs-variables.scss';

// 3. Include remainder of required Bootstrap stylesheets
@import 'bootstrap/scss/variables';

@import './_bs-utilitiy-variables.scss';

@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/utilities';

@import './_bs-utilities';

@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

@import 'ngx-toastr/toastr';
// import theme
@import
'mixins/borders',
//'mixins/buttons',
// 'ui/tags',
// 'ui/_lists',
// 'ui/carousel',
// 'ui/_datepicker.scss',
// 'ui/_timepicker.scss',
// 'ui/popover',
// 'ui/toasts',
// 'ui/switch',
// 'ui/tel-input',
'ui/paging',
'ui/text',
'ui/tabs',
'ui/tables',
'ui/modals',
'pages',
'ui/editor',
'ui/misc',
'ui/navbar',
'ui/buttons',
'ui/cards',
'ui/contact-card-envelop',
'ui/contact-tags',
'ui/dropdown',
'ui/images',
'ui/inputs',
'ui/circle-tabs',
'ui/typography',
'ui/_scrollbars',
'ui/tooltip',
'ui/search',
'components/accounts/_accounts-grid',
'components/accounts/_accounts',
'components/broadcast/broadcast',
'components/media-db/media-db',
'components/media-db/outlets-page',
'components/media-db/journalists-page',
'components/media-db/outlet-card.layout',
'components/media-db/journalist-card.themes',
'components/contact/_contacts',
'components/contact/_media',
'components/contact/_profile',
'components/contact/_timeline',
'components/contact/_contacts-page',
'components/contact/contact-card.layout',
'components/contact/contacts-bulk-actions',
'components/contact/contacts-table/contact-row.layout',
'components/dashboard/charts',
'components/dashboard/spinner',
'components/dashboard/nav',
'components/firm/_firm-grid',
'components/funnel/_funnel-grid',
'components/funnel/_funnel',
'components/login/_login-grid',
'components/login/_login',
'components/milestones/_milestones-grid',
'components/pitching/_pitching-grid',
'components/pitching/_pitching',
'components/user/_user-grid',
'components/support/firm-grid',
'components/support/user-management',
'components/media-lists/media-lists-grid',
'components/media-monitoring/social-alert-form',
'components/media-monitoring/online-alert-form',
'components/media-monitoring/card-tags',
'components/media-monitoring/broadcast-alert-form',
'components/messaging/messaging',
'components/browser-type/browser-type',
'components/media-monitoring/media-monitoring-page',
'components/media-monitoring-dashboard/media-monitoring-dashboard-page',
'components/monitoring-schedule/monitoring-schedule-page',
'components/plg/plg_styles',
'antd/dropdown';

/* Mixins usage */
@include border-radius-pixel-classes(16, 24, 32);

