.card {
    position: relative;
    border: none;
    box-shadow: 2px 2px 2px #e7e7e7;
    .card-header {
        background-color: white;
        border-bottom: none;
    }
    .card-title {
        font-size: 13px;
    }
    &.underlay-primary {
        background-color: rgba( $primary, .85 );
        .card-header {
            background-color: transparent;
        }
        .card-body {
            position: relative;
        }
        .card-footer {
            background-color: transparent;
            border-top: none;
            .articles-list {
                list-style-type: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                li {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-bottom: 2px solid white;
                    padding-bottom: 8px;
                    margin-bottom: 8px;
                    a {
                        color: white;
                    }
                    span {
                        color: white;
                        &.left {
                            width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space:nowrap
                        }
                    }
                }
            }
        }
    }
    .card-edit {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}
