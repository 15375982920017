.modal-close-btn {
    font-size: 8px;
    padding: 13px !important;
}

.modal-footer {
    padding: 0.75rem;
}

.modal-body {
    padding: 1.5rem;
}


