app-milestones {
    display: grid;
    grid-area: content;
    grid-template-rows:  min-content 1fr;
    grid-template-columns: auto;
    grid-template-areas: "header" "one";
    grid-row-gap: 2px;
    position: relative;
    @include media-breakpoint-down(sm) {
        grid-template-areas:
            "header"
            "one";
    }
    header {
        grid-template-rows: auto;
        grid-template-columns: auto 1fr auto;
        grid-template-areas:
            "title middle utility";
            .inner {
                grid-area: middle;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            .utility {
                grid-area: utility;
            }
            @include media-breakpoint-down(sm) {
                grid-template-rows:  auto auto 1fr;
            grid-template-columns: auto;
            grid-template-areas:
                "title"
                "middle"
                "utility";
        }
    }
    app-milestones-table {
        grid-area: one;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        left: 0; right: 0;
        height: 100%;
        padding-bottom: 1rem;
        @include media-breakpoint-down(sm) {
            position: initial;
            height: auto;
        }
    }
}
