.ngx-toolbar {
    background-color: white !important;
    border: none !important;
}

.ngx-editor-textarea {
    border: 1px dotted #ddd;
}

app-ngx-grippie {
    display: none !important;
}


quill-editor {
    .ql-toolbar.ql-snow {
        border-color: $border-color;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .ql-container.ql-snow {
        border-color: $border-color;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}