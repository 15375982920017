.media-tick {
    position: relative;
    height: 2rem;
    width: 2rem;
    input {
        position: absolute;
        top: 0;
        height: 2rem;
        width: 2rem;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
    }
    label {
        &:before {
            position: absolute;
            color: $primary;
            font-weight: 900;
            font-size: 2rem;
            line-height: 2rem;
            top: 0;
            height: 2rem;
            width: 2rem;
            text-align: center;
        }
    }
    input:checked + label {
        &:before {
            position: absolute;
            content: "\f14a";
            color: $primary;
        }
    }
    &.small {
        height: 1rem;
        width: 1rem;
        margin-right: 1.5rem;
        input {
            height: 1rem;
            width: 1rem;
        }
        label {
            &:before {
                top: -2px;
                font-size: 1rem;
                line-height: 1rem;
                height: 1rem;
                width: 1rem;
            }
        }
    }
}
