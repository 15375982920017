@mixin tagThemes {
    @each $name, $hex in $media-list-card-colors {
        &-#{"" + $name} {
            .tag {
                background-color: white;
                color: $hex;
                .tag-title {
                    color: $hex;
                }
            }
        }
        &-default {
            .tag {
                background-color: $gray-200;
                color: $gray-700;
                .tag-title {
                    color: $gray-700;
                }
            }
        }
    }
}

app-card-tags {
    .card-tags-wrapper {
        display: inline-block;
        overflow: auto;
        position: relative;
    }
    .tag {
        background-color: $gray-300;
        color: $gray-700;
    }
    .tag-title {
        line-height: 1rem;
        text-align: left;
        vertical-align: middle;
        width: 100%;

    }
    button {
        background-color: transparent;
        color: $gray-700;
        box-shadow: none;
        margin-top: .3rem;
        margin-right: .25rem;
    }
    &.color-scheme {
        @include tagThemes();
    }

}
