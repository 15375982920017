app-social-alert-form {
  .form-check {
    display: flex;
    height: 30px;
    align-items: center;
  }

  .form-group {
    margin-bottom: 2rem;
  }

  app-alert-form-doc {
    background-color: $gray-100;
  }

  .tab-content {
    height: 100%;
  }

  .z-index-100 {
    z-index: 100;
  }
}
