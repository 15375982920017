app-media-monitoring-dashboard-page {
    .modal-dialog{
        .modal-content {
            border: none;


        }
        &.modal-lg {
            @media (min-width: 992px) {
                max-width: 67vw;
                min-height: 509px;
                .modal-content {
                    min-height: 65vh;
                }
            }

        }
        .modal-view-card {
            border: 1px solid $gray-200;
            border-radius: 12px;
        }

    }
}
