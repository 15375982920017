canvas {
    max-width: 100% !important;
}

#group {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 300px;
    width: 300px;
    margin: auto;
    .graph1, .graph2, .graph3 {
        position: absolute;
    }
    .graph1 {
        width: 100%;
        height: 100%;
    }
    .graph2 {
        width: 82%;
        height: 82%;
    }
    .graph3 {
        width: 64%;
        height: 64%;
    }
}
.stats-legend {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    margin-bottom: 2rem;
    padding: 0;
    li {
        display: inline-block;
        margin-right: .25rem;
    }
}

.pie-tooltip {
    background-color: $gray-700;
    color: white;
    display: table;
    font-family: $font-family-sans-serif;
    font-size: .75rem;
    padding: .25rem;
    span {
        vertical-align: middle;
    }
    .chartjs-tooltip-key {
        border-radius: 100%;
        display: inline-block;
        height: 13px;
        margin-right: 5px;
        width: 13px;
    }
}

.bar-tooltip {
    background-color: $white;
    border: 1px solid $gray-300;
    color: $secondary;
    display: table;
    font-family: $font-family-sans-serif;
    font-size: .75rem;
    padding: .25rem;
    span {
        vertical-align: middle;
    }
    .chartjs-tooltip-key {
        display: none;
    }
    .title {
        display: none;
    }
    .label {
        color: black;

    }
}

.line-tooltip {
    background-color: $white;
    border: 1px solid $gray-300;
    color: $secondary;
    display: table;
    font-family: $font-family-sans-serif;
    font-size: .75rem;
    padding: .25rem;
    span {
        vertical-align: middle;
    }
    .chartjs-tooltip-key {
        display: none;
    }
    .title {
        display: none;
    }
    .label {
        color: black;

    }
}

.stacked-tooltip {
    background-color: $white;
    border: 1px solid $gray-300;
    color: $secondary;
    display: table;
    font-family: $font-family-sans-serif;
    font-size: .75rem;
    padding: .25rem;
    span {
        vertical-align: middle;
    }
    .chartjs-tooltip-key {
        display: none;
    }
    .title {
        display: none;
    }
    .label {
        color: black;
        font-size: 1rem;
    }
}
