app-online-alert-form {
    .form-check {
        display: flex;
        height: 30px;
        align-items: center;
    }

    .form-group {
        margin-bottom: 2rem;
    }

    app-alert-form-doc {
        background-color: $gray-100;
    }

    .border-gray {
        border-color: $gray-200;
    }
}
