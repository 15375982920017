app-funnel-grid {
    .board-container {
        padding: 0 56px;
        width: 100%;
        height: calc(100vh - 191px);
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;

        .board-column {
            width: 252px;
            height: 100%;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            white-space: nowrap;
            &:first-of-type {
                border-left: none;
            }
            .column-content {
                box-sizing: border-box;
                display: grid;
                grid-template-rows: auto 1fr;
                height: 100%;
                position: relative;
                white-space: normal;
                background-color: $white-300;
                margin-right: 0.25rem;
                margin-left: 0.25rem;

                .column-heading {
                    // align-items: center;
                    // color: $secondary;
                    // padding: .25rem .5rem;
                    // .column-heading-text {
                    //     font-size: .9rem;
                    // }
                    // .column-heading-count {
                    //     //border: 1px dotted;
                    //     border-radius: 100%;
                    //     display: block;
                    //     font-size: .75rem;
                    //     height: 1.85rem;
                    //     line-height: 1.85rem;
                    //     margin-left: auto;
                    //     text-align: center;
                    //     width: 1.85rem;
                    //     float: right;
                    // }
                }
            }
        }
    }

    .funnel-card {
        border: 1px solid $gray-50;
    }
}

.cdk-drag {
    cursor: move;
    &:active {
        cursor: grabbing;
    }
}

.cdk-drop-list-dragging {
    box-shadow: inset 0 0 0 1px green;
    opacity: .4;
}

.placeholder-text {
    color: $gray-400 !important;
    font-size: 0.875em;
}

