.btn.btn-outline-primary {
    @include button-outline-variant($primary-300,
    $color-hover: color-contrast($primary-300),
    $active-background: $primary-300,
    $active-border: $primary-300,
    $active-color: color-contrast($primary-300));
}

.btn.btn-outline-secondary {
    @include button-outline-variant($secondary,
    $color-hover: color-contrast($secondary),
    $active-background: $secondary,
    $active-border: $secondary,
    $active-color: color-contrast($secondary));
}

//.btn.btn-primary {
//    @include button-variant($primary-300, $primary-300);
//}

// based on propel design system

.btn.btn-primary {
    background-color: $primary-600;
    color: $white;
    border: none;

    &:hover {
        background-color: $primary-700;
    }

    &:active {
        background-color: $primary-800;
    }

    &:focus {
        background-color: $primary-800;
    }

    &:disabled {
        background-color: $gray-100;
        color: $text-tertiary;
    }
}

.btn.btn-secondary{
        background-color: $primary-600;
    color: $white;
    border: none;

    &:hover {
        background-color: $primary-700;
    }

    &:active {
        background-color: $primary-800;
    }

    &:focus {
        background-color: $primary-800;
    }

    &:disabled {
        background-color: $gray-100;
        color: $text-tertiary;
    }
}

    //color: $text-light;
    //border: none;
    //background-color: $btn-secondary;
    //
    //&:hover {
    //    background-color: $btn-secondary-hover;
    //}
    //
    //&:active {
    //    background-color: $btn-secondary-hover;
    //}
    //
    //&:focus {
    //    background-color: $btn-secondary-focused;
    //}
    //
    //&:disabled {
    //    background-color: $white-300;
    //    color: $text-tertiary;
    //}
//}

.btn.btn-tertiary{

    color: $text-primary;
    border-color: $border-primary;
    background: $white;

    &:hover {
        background-color: $white-100;
    }

    &:active {
        background-color: $white-100;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(#007bff, 0.5);
    }

    &:disabled {
        background-color: $white-300;
        color: $text-tertiary;
    }
}

.btn.btn-cyan{

    color: $black;
    border-color: transparent;
    background: $cyan;

    &:hover {
        background-color: $white-100;
    }

    &:active {
        background-color: $white-100;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(#76E2FF, 0.5);
    }

    &:disabled {
        background-color: $white-300;
        color: $text-tertiary;
    }
}


.btn.btn-link {
    color: $text-link;

    &:hover {
        color: $text-link-hover;
    }

    &:active {
        color: $text-link-hover;
    }
    &:disabled {
        color: #D0D5DD;
    }
}

.btn.btn-danger {
    color: $red-orange;
    background-color: $gray-95;
    border: .1px solid $gray-200;
    &:hover {
        color: $red-orange;
    }
}
