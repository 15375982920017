app-account-setup {
    .col-md-9 > .card {
        min-height: 75%;
        .card-body {
            align-items: stretch;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
    }
}

app-account-form, app-quick-account-form {
    height: 100%;
    flex: auto;
    display: flex;
    flex-direction: column;
    > form {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: space-between;
    }
}
