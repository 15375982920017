//@mixin borderSize($class, $sm, $md, $lg, $type: solid, $color: inherit) {
//    .#{$class}-none {
//        border: none;
//    }
//    .#{$class}-sm {
//        border: $sm + px $type $color;
//    }
//    .#{$class}-md {
//        border: $md + px $type $color;
//    }
//    .#{$class}-lg {
//        border: $lg + px $type $color;
//    }
//}
//
//@mixin borderRadius($class, $sm, $md, $lg, $max) {
//    .#{$class}-none {
//        border-radius: none;
//    }
//    .#{$class}-sm {
//        border-radius: $sm + '%';
//    }
//    .#{$class}-md {
//        border-radius: $md + '%';
//    }
//    .#{$class}-lg {
//        border-radius: $lg + '%';
//    }
//    .#{$class}-max {
//        border-radius: $max + '%';
//    }
//}


@mixin borderRadiusPx($radius) {
    border-radius: $radius + px;
}

@mixin border-radius-pixel-classes($values...) {
    @each $value in $values {
        .br-px-#{$value} {
            border-radius: #{$value}px;
        }
    }
}

@mixin border-radius-em-classes($values...) {
    @each $value in $values {
        .br-em-#{$value} {
            border-radius: #{$value}em;
        }
    }
}
