@import '../colors';

app-navbar {
    .navbar {
        margin-bottom: 0;
        box-shadow: 2px 2px 4px $gray-400;
        background: #242B3A;

        .nav-link {
            color: rgba(255,255,255, 0.7);

            &.active {
                color: $white-100;
            }
            &:hover {
                color: $white-100;

            }
        }
    }

    .notification-circle {
        padding: 6px;
    }

    .user-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
    }
    .nav {
        .nav-link {
            margin-left: 16px;
            margin-right: 16px;
        }

        .nav-link.active {
            position: relative;
        }

        .nav-link.active:after {
            content: "";
            position: absolute;
            height: 4px;
            background: $orange;
            width: 100%;
            left: 0;
            bottom: -9px;
            border-radius: 20px 20px 0 0;
        }

        .nav-link:hover{
            color: $gray-900;
            path {
                stroke: $gray-900;
                fill: $gray-900;
            }
        }
    }

    .shortcuts-row {
        div {
            &:hover {
                path {
                    stroke: $gray-900;
                    fill: $gray-900;
                }
                p {
                    color: $gray-900;
                    font-weight: 600;

                }
                color: $gray-900;
            }
        }
    }
}
