app-funnel, app-source-requests-funnel-page{
  header {
    align-items: center;
    padding: 0 56px;

    .filters {
      grid-area: filters;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    .utility1 {
      grid-area: utility1;
      margin-left: auto;
      margin-top: .5rem;
    }

    .utility2 {
      grid-area: utility2;
      margin-left: auto;
    }

    @include media-breakpoint-down(sm) {
      grid-template-rows: auto auto 1fr;
      grid-template-columns: auto;
      grid-template-areas: "title" "middle" "utility";
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}
