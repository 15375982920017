app-pi-form, app-quick-pi-form {
    height: 100%;
    flex: auto;
    display: flex;
    flex-direction: column;
    > form {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 2;
    }
}

app-milestones-form {
    height: 100%;
    flex: auto;
    display: flex;
    flex-direction: column;
    > form {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: space-between;
    }
}
