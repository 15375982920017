app-media-monitoring-page {
    .sidebar {
        box-shadow: 2px 2px 4px $gray-400;
        transition: width 0.25s, min-width 0.25s;
    }

    .tab-content {
        display: flex;
        flex: 1 1 auto !important;
        overflow: hidden;
    }

    .nav-tabs .nav-link.active {
        background-color: transparent;
    }

    .tab-pane {
        border: 0;
        box-shadow: 2px 2px 4px $gray-400;
        padding: 0;
    }

    .alert {
        margin: 0 !important;
    }

    .modal-dialog{
        .modal-content {
            border: none;
            padding: .5rem 1rem;
            .modal-header {
                border-bottom: 0;
            }
            .modal-body {
                align-items: stretch;
                //display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
            }
        }
        &.modal-lg {
            @media (min-width: 992px) {
                max-width: 67vw;
                min-height: 509px;
                .modal-content {
                    min-height: 65vh;
                }
            }

        }
        .modal-view-card {
            border: 1px solid $gray-200;
            border-radius: 12px;
        }

    }

    .not-found-icon {
        font-size: 140px;
    }

    .graph-page-select {
        .form-control {
            border: none;
            width: fit-content;
            span {
                font-weight: $font-weight-regular;
                font-size: 0.875rem;
                padding-right: 10px;
                color: black !important;
                background: none;
            }
            & + span {
                font-weight: $font-weight-regular;
                font-size: 0.875rem;
                padding-right: 10px;
                color: black !important;
                background: none;
            }
        }
        .dropdown-menu {
            width: fit-content;
        }

    }

    .highcharts-title {
        visibility: hidden !important;
    }

    .monitoring-sidebar{
        text-decoration: none;
        .panel-heading {
            padding: 0;
        }
        .panel-open {
            border-left: solid $success;

            .panel-heading {
                border-bottom: 1px solid $light;
            }
        }
        .card {
            border-radius: 0;
        }
        .panel-body {
            padding: 0;
        }
        .list-group-item {
            border: none;
            border-bottom: 1px solid $light;
            &.active {
                background: lighten($success, 45) !important;
                border: none;
                .mention-count-alert {
                    color: darken($success, 10) !important;
                    border: 1px solid $success !important;
                    font-weight: normal !important;
                }
            }
            a {
                text-decoration: none;
                color: $dark;
            }
        }
    }

    .border-gray{
        border-color: $gray-200;
    }
}
