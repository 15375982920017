.text-orange {
  color: $orange2;
}

.bg-orange {
  background-color: $orange2;
}

.bg-teal-50 {
  background: $teal-50;
}
